.full-moon {
  height: 12px;
  width: 12px;
  background-color: rgb(0, 0, 0);
  border-radius: 50%;
  display: inline-block;
  margin-left: 5px;
}

.new-moon {
  height: 10px;
  width: 10px;
  border: 2px solid black;
  background-color: black;
  border-radius: 50%;
  display: inline-block;
  margin-left: 5px;
}

.condensed-bottom{
  align-self: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-bottom: 50px;
}

.condensed-bottom > div{
  align-self: center;
}

.moon-key{
  display: flex;
  width: 100%;
  flex-direction: row;
  justify-content: space-around;
}

.moon-key-item{
  display: flex;
  align-content: center;
  justify-content: center;
}

.moon-key-item > div {
  align-self: center;
  margin-right: 5px;
}


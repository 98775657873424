.new-calendar-content{
  padding: 5px 10px 5px 10px;
  max-width: 400px;
}

.input-combo{
  margin-top: 10px;
  margin-bottom: 10px;
  display: block;
}

.input-combo label{
  display: block;
  font-weight: bold;
}

.input-combo.bk label{
  color: rgb(36, 36, 36);
}

.new-calendar-content button {
  display: block;
  border: 1px solid black;
  border-radius: 0px;
  background-color: rgb(0, 113, 212);
  font-size: 18px;
  color: white;
  margin-left: auto;
  margin-right: auto;
  margin-top: 10px;
}

.gray-box{
  border: 1px solid rgb(65, 65, 65);
}
.year-header{
  font-size: 2em;
  position: sticky;
  top: 34px;
  display: flex;
  justify-content: center;
  color: rgb(255, 255, 255);
  background-color: rgba(53, 47, 110, 0.849);
  padding: 0px 10px 0px 10px;
  flex-wrap: wrap;
  z-index: 100;
  box-shadow: 0px 0px 3px 0px rgb(0, 0, 0);
}

.calendar-toolbar-color{
  color: rgb(255, 255, 255);
}

.calendar-toolbar-color a{
  color: rgb(255, 255, 255);
}

.calendar-bar-main{
  display: flex;
}

.calendar-year-link{
  color: rgb(255, 255, 255);
  margin-left: 20px;
  margin-right: 20px;
}

.calendar-year-current-number{
  margin-right: 20px;
}

.calendar-bar-left{
  flex: 1;
  margin-right: auto;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
}

.calendar-bar-right{
  display: flex;
  flex: 1;
}

.share-div{
  display: flex;
  align-content: center;
  justify-content: center;
  cursor: pointer;
  font-size: 18px;
}

.calendar-select{
  display: flex;
  align-content: center;
  justify-content: center;
  cursor: pointer;
  font-size: 18px;
  margin: 5px 0px 5px 0px;
}

.share-div div:first-child{
  margin-right: 10px;
}

.share-div div{
  align-self: center;
}


.calendar-bar-main{
  flex: 3;
  display: flex;
  justify-content: center;
}

.view-picker span{
  align-self: center;
}

.view-picker-down-arrow{
  padding-top: 4px;
}

@media (orientation: portrait), (max-width: 650px) {
  .year-header{
    flex-direction: column-reverse;
    align-content: center;
  }
  .calendar-bar-right{
    display: none;
  }
  .calendar-bar-main{
    height: 100%;
    display: flex;
    flex-direction: row;
    align-content: center;
    vertical-align: middle;
    justify-content: center;
  }
  .ct-small-portrait{
    align-self: center;
    font-size: 20px;
    height: 100%;
  }
  .ct-angle-icon{
    padding: 0px 10px 0px 10px;
  }
}



@media (orientation: landscape) and (min-width: 650px)  {
  .share-left{
    display: none;
  }
}


.share-content{
  position: absolute;
  top: 40px;
  border: 1px solid gray;
  background-color: white;
  padding: 5px 10px 5px 10px;
}

.share-content .share-combo:first-child{
  margin-bottom: 20px;
}

.share-content input{
  width: 100%;
}

.share-text{
  white-space: nowrap;
  color: black;
}

.share-button-input{
  display: flex;
  flex-direction: row;
}

.share-button-input button{
  margin-right: 10px;
}



@media (orientation: portrait), (max-width: 650px) {
  .hide-portrait {
    display: none !important;
  }
}

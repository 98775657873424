.standard-modal-inner{
  padding-left: 20px;
  padding-right: 20px;
  padding-bottom: 40px;
  display: flex;
  flex-direction: column;
}


@media only screen and (min-width: 600px) {
  .standard-modal-inner{
    min-width: 500px;
  }
  .standard-modal-inner.sm{
    min-width: 300px;
  }
}

@media only screen and (max-width: 600px) {
  .standard-modal-inner{
    width: 90vw;
  }
}

/* @media (orientation: portrait) {
  .standard-modal-inner{
    max-width: 90vw;
  }
} */

.event-description{
  max-width: 350px;
}

.event-detail-top{
  background-color: rgb(192, 202, 255);
  position: sticky;
  top: 0;
}

.event-detail-title{
  padding-bottom: 0px;
  margin-bottom: 0px; 
}

.event-detail-container{
  background-color: rgb(192, 202, 255);
  padding: 0px 10px 5px 10px;
  margin: 10px 0px 10px 0px;
  max-height: 500px;
  overflow: auto;
}

.event-edit-icon{
  font-size: 10px;
  vertical-align: top;
  margin-top: 5px;
}

.event-real-date{
  margin-bottom: 10px;
  font-size: 10px;
}

.standard-modal-inner h2{
  color: rgb(13, 13, 139);
  align-self: center;
}

.edit-event-area{
  margin-top: 30px;
}


.edit-event-area textarea {
  margin-top: 5px;
  display: block;
  border: 1px solid black;
  border-radius: 0px;
  font-size: 14px;
  height: 100px;
  width: 100%;
}

.edit-event-area div input{
  width: 100%;
}

.add-event-button{
  background-color: rgb(3, 114, 27);
  border: 1px solid rgb(34, 34, 34);
  color: white;  
  font-size: 18px;
  margin-top: 20px;
  width: 200px;
  margin-left: auto;
}

.real-world-date-line{
  display: flex;
}
.real-world-date-line div{
  word-wrap: none;
}

.real-world-date-line input{
  max-width: 300px;
  border: 1px solid gray;
}

.hidden-line{
  display: flex;
  flex-direction: row;
  align-content: center;
}

.hidden-line > div:first-child{
  display: flex;
  margin-right: 5px;
  min-width: 20px;
}

.hidden-line > div:first-child> input{
  margin-left: 0;
  padding-left: 0;
  margin-top: 6px;
  color: black;
}

.edit-event-buttons{
  display: flex;
  justify-content: space-between;

}

.edit-event-buttons button {
  width: 100px;
  display: block;
  border: 1px solid black;
  border-radius: 0px;
  font-size: 18px;
  color: white;
  margin-top: 10px;
}

.edit-event-buttons button:nth-child(1) {
  background-color: rgb(212, 0, 0);
}

.edit-event-buttons button:nth-child(2) {
  background-color: rgb(0, 113, 212);
}

.event-edit-date{
  margin-top: 20px;
  display: flex;
  justify-content: center;
}

.event-edit-date span{
  align-self: center;
  margin-left: 10px;
}

.edit-event-date{
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  width: 100%;
}

.edit-event-date input{
  max-width: 75px;
}

.edit-event-date-combo label {
  display: block;
  font-weight: bold;
  color: rgb(13, 13, 139);
}

.calendar-edit-input-combo .edit-event-date-combo label{
  color: white;
}



.edit-date-cancel{
  margin-left: 10px;
  font-size: 10px;
}
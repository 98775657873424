.login-modal{
  height: 100px;
  width: 200px;
  text-align: center;
  padding: 20px;
  font-weight: bold;
  background-color: rgb(236, 236, 236);
}

.sign-in-text{
  margin-bottom: 20px;
}

.google-login-button{
  background-color: #2b6dd8;
  border: 1px solid black;
  color: white;
  font-size: 18px;
  padding: 5px 10px 5px 10px;
  display: flex;
}

.google-login-button > div{
  display: flex;
  flex-direction: row;
}

.google-login-button:hover{
  background-color: #255fbb;
}

.g-wrap{
  background-color: white;
  height: 40px;
  width: 40px;
}


.g-wrap img{
  height: 40px;
  width: 40px;
}

.p-loader-wrap{
  background-color: #2b6dd8;
  position: relative;
  width: 100%;
  height: 100%;
}

.p-loader-wrap:hover{
  background-color: #255fbb;
}

.days{
  margin-top: 20px;
}

.edit-day-line{
  display: flex;
}

.edit-day-line > div{
  flex: 1;
}

.fa-cancel{
  color: red;
  margin: 0px 5px 0px 5px;
  cursor: pointer;
}

.fa-check{
  color: green;
  margin: 0px 5px 0px 5px;
  cursor: pointer;
}

.delete-day-buttons{
  display: flex;
  margin-top: 20px;
  width: 100%;
}

.delete-day-buttons button:first-child{
  margin-right: auto;
}

.top-bar{
  background-color: rgba(58, 58, 58);
  display: flex;
  justify-content: space-between;
  position: sticky;
  top: 0;
  color: rgb(236, 236, 236);
  height: 34px;
  padding-right: 20px;
  z-index: 200;
}

.top-bar .tool-child{
  align-self: center;
  cursor: pointer;
}

.top-bar img{
  width: 80px;
  padding: 5px;
}

.top-bar .title{
  font-weight: bold;
  display: flex;
  flex-direction: column;
  justify-content: center;
  font-family: Georgia, 'Times New Roman', Times, serif;
}

.calendar-dd-wrap{
  position: relative;
}

.calendar-dd{
  position: absolute;
  background-color: rgba(58, 58, 58);
  color: rgb(236, 236, 236);
  padding-left: 10px;
  margin-left: -10px;
  padding-bottom: 10px;
  z-index: 5000;
  display: flex;
  flex-direction: column;
}



.calendar-dd-item{
  display: flex;
  width: 100%;
}

.calendar-dd-item > div:first-child{
  margin-right: 20px;
}

.calendar-dd-item > div:nth-child(2){
  margin-right: 10px;
}

.calendar-dd-name{
  white-space: nowrap;
}

.calendar-edit-dd{
  margin-left: auto;
}





.calendar-dd .calendar-dd-item{
  margin-top: 5px;
}

/* .calendar-dd .calendar-dd-item:not(:last-child){
  border-bottom: 1px solid white;
} */

.hover-bold:hover{
  font-weight: bold;
}
.hover-underline:hover{
  text-decoration: underline;
}

.toolbar-link {
  text-decoration: none;
  color: white;
}

.toolbar-link:hover{
  text-decoration: underline;
}

@media only screen and (max-width: 600px) {
  .user-toolbar-user-name{
    display: none;
  }
}

.right-toolbar{
  display: flex;
}

.right-toolbar > div{
  margin-right: 20px;
}

/* @media only screen and (max-width: 600px) {
  .about-toolbar-text{
    display: none;
  }
}

@media only screen and (min-width: 601px) {
  .about-toolbar-icon{
    display: none;
  }
} */
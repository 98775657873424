/* .moons{
  margin-top: 20px;
} */

.moon {
  height: 10px;
  width: 10px;
  background-color: rgb(0, 0, 0);
  border-radius: 50%;
  display: inline-block;
  margin-left: 5px;
}

.moon-entry{
  margin: 5px 0px 5px 0px;
}

.moon-line{
  display: flex;
}

.moon-line > div{
  align-self: center;
}

.moon-cycle{
  font-size: 0.8em;
}

.fa-moon{
  font-size: 0.8em;
  margin-left: 10px;
  align-self: center;
}

.moon-color-example{
  background-color: gray;
  max-width: 100px;
  padding: 5px 10px 5px 10px;
  position: relative;

}

.color-picker-wrapper > div {
  position: absolute;
}

.color-picker-wrapper > div  > div{
  position: absolute;
}

.timeline-events{
  display: flex;
  flex-direction: column;
  align-content: center;
  height: 100%;
}

.timeline-events > div{
  align-self: center;
  cursor: pointer;
}

.timeline-events > div:first-child{
  margin-top: 20px;
}

.timeline-event{
  border: 4px solid rgb(29, 94, 179);
  border-radius: 3px;
  background-color: rgba(255, 255, 255, 0.95);
  padding: 5px 10px 5px 10px;
  -webkit-box-shadow: 4px 7px 5px 0px rgba(0,0,0,0.75);
  -moz-box-shadow: 4px 7px 5px 0px rgba(0,0,0,0.75);
}

@media only screen and (min-width: 450px) {
  .timeline-event{
    width: 400px;
  }
}

@media only screen and (max-width: 450px) {
  .timeline-event{
    width: 80vw;
  }
}

.timeline-event h4{
  margin: 0px 0px 0px 0px;
}

.timeline-event-header{
  margin: 5px 0px 10px 0px;
}

.timeline-date{
  font-size: 12px;
}

.timeline-event-name-edit{
  display: flex;
}

.timeline-event-name-edit > h4{
  color: rgb(42, 54, 77);
}

.timeline-event-name-edit > div{
  margin-left: auto;
  color: rgb(139, 139, 255);
}


@media only screen and (orientation: landscape) and (min-height: 600px) {  
  .add-event-timeline{
    position: fixed;
    bottom: 0;
    right: 0;
    margin-right: 30px;
    margin-bottom: 15px;
  }
}

.timeline-connector{
  display: flex;
}

.timeline-connector > div{
  flex: 1;
}

.timeline-connector.solid > div:first-child{
  border-right: 5px solid rgb(29, 94, 179);
}


.timeline-connector.dashed > div:first-child{
  border-right: 5px dotted rgb(29, 94, 179);
}


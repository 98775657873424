
@media screen and (orientation: landscape) and (min-width: 650px)  {
  .calendar{
    background-repeat: no-repeat;
    background-attachment: fixed;
    background-image: url("../Images/background/clock.svg");
    background-size: 90%;
    background-position: center;
  }
}

.calendar {
  min-height: calc(100vh - 34px);
}

.calendar-body{
  margin-top: 20px;
}

.calendar-edit-view{
  display: flex;
  flex-direction: column;
  width: 100%;
  background-color: rgb(26, 47, 73);
  color: white;
  padding-bottom: 20px;
  min-height: 100vh;
}

.calendar-edit-view > div{
  align-self: center;
}

.calendar-name{
  font-size: 2em;
  border-width: 2px;
  margin-top: 5px;
  margin-bottom: 5px;
}

#calendar-edit-name-input{
  font-size: 20px;
  margin-top: 10px;
  margin-bottom: 10px;
  text-align: center;
}

.calendar-edit-input-combo{
  margin-bottom: 10px;
  text-align: center;
  min-width: 300px;
}

.calendar-edit-input-combo > label{
  display: block;
  font-weight: bold;
  margin-top: 10px;
  margin-bottom: 5px;
}

.calendar-edit-input-combo input{
  margin-top: 0px;
  display: block;
}

.calendar-num-days-tooltip{
  position: absolute;
  z-index: 100;
  background-color: rgb(122, 179, 255);
  border: 1px solid black;
  border-radius: 5px;
  max-width: 200px;
  padding: 5px 10px 5px 10px;
}

.day-number-info-icon{
  font-size: 8px;
  vertical-align: top;
  padding-top: 3px;
  color: rgb(122, 179, 255);
}

.num-days-in-month{
  font-size: 10px;
}

.edit-title{
  padding: 0px 0px 0px 0px;
  margin: 0px 0px 0px 0px;
  font-size: 20px;
  font-weight: normal;
}

.month-edit-icon{
  font-size: 12px;
  vertical-align: top;
  padding-top: 3px;
  cursor: pointer;
}

.month-dd{
  border: 1px solid rgb(58, 58, 58);
}

.month-dd.dragging{
  background-color: rgb(55, 87, 126);
  border-color: white;
}

.day-num-input{
  max-width: 50px;
}

.month-edit-container{
  min-width: 200px;  
}

.day-input-row{
  display: flex;
  flex-direction: row;
}

.edit-month-save{
  margin-left: auto;
  margin-right: 10px;
  border: 1px solid black;
  background-color: rgb(47, 119, 233);
  color: white;
}

.month-edit-mode{
  background-color: rgb(45, 84, 131);
  padding-top: 10px;
  padding-bottom: 10px;
}

.danger-modal{
  padding: 10px 20px 10px 20px;
  background-color: rgb(190, 9, 9);
  color: white;
  font-size: 2em;
}

.delete-buttons{
  display: flex;
  flex-direction: column;
}

.no-delete-btn{
  margin-top: 20px;
  font-size: 1em;
  background-color: rgb(16, 180, 92);
  margin-left: auto;
  border-color: black;
}

.yes-delete-btn{
  margin-top: 20px;
  font-size: 12x;
  background-color: rgb(235, 252, 0);
  border-color: white;
  color: black;
}

.month-dialog-buttons{
  display: flex;
  flex-direction: row;
  align-self: flex-end;
  margin-top: 20px;
  justify-content: space-between;
  width: 100%;
}

.add-month-button{
  min-width: 50px;
}

.edit-calendar-main{
  display: flex;
  flex-direction: row;
  padding-bottom: 20px;
  align-self: center;
  justify-content: space-evenly;
  flex-wrap: wrap;
  margin-left: -40px;
  align-content: flex-end;

}

.edit-calendar-col{
  margin-left: 40px;
  align-self: flex-start;
  margin-top: 20px;
}

.edit-calendar-col:nth-child(2){
  min-width: 200px;
}

.holiday-entry{
  display: flex;
  flex-direction: column;
  margin-bottom: 10px;
}

.holiday-entry > div:nth-child(1){
  display: flex;
  flex-direction: row;
}

.holiday-entry > div:nth-child(1) > div:nth-child(2){
  margin-left: 10px;
  font-size: 0.8em;
}

.holiday-entry > div:nth-child(2){
  font-size: 12px;
}

/* @media (orientation: portrait) {
  .landing-content{
    flex-direction: column;
  }
  .intro-text{
    padding-right: 20px;
  }
} */

.holidays{
  margin-top: 20px;
}


.leap-year-edit{
  display: flex;
}

.leap-year-edit > div:first-child{
  margin-right: 10px;
}

.leap-year-edit > div:nth-child(2){
  cursor: pointer;
}



@media (orientation: portrait), (max-width: 650px) {
  .calendar-save-col{
    display: none;
  }
}

@media (orientation: landscape) and (min-width: 650px)  {
  .calendar-save-bottom{
    display: none;
  }
}


.days-header{
  display: flex;
}

.days-header > div{
  align-self: center;
}
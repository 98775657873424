.spin-wrapper{
  height: 100%;
  margin-top: 100px;
  padding: 0;
  margin: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 100px;
}


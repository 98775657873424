.App-logo {
  height: 40vmin;
}

/* .App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
} */

.App-link {
  color: #09d3ac;
}


.month{
  margin-left: 5px;
  margin-right: 5px;
  display: inline-flex;
  flex-direction: column;
  align-content: center;
  margin-bottom: 20px;
  border-collapse: collapse;
  background-color: rgba(67, 104, 150, 0.95);
  color: white;
  padding-left: 15px;
  padding-right: 15px;
  border: 1px solid black;
}

.month.no-events{
  background-color: rgba(96, 123, 155, 0.8);
}

.month-name{
  font-size: 18px;
  margin-bottom: 5px;
}


.week{
  display: flex;
  width: auto;
  
}

.day{
  /* width: 75px;
  height: 75px; */
  cursor: pointer;
  margin-left: 1px;
  margin-bottom: 1px;
}

/* @media only screen and (max-width: 600px) {
  .day{
    width: 50px;
    height: 50px;
  }
} */

@media (orientation: landscape) and (min-width: 650px)  {
  .day-width{
    width: 75px;
    height: 75px;
  }
}

@media (orientation: portrait), (max-width: 650px) {
  .day-width.seven{
    width: 12vw;
    height: 12vw;
  }
  .day-width.eight{
    width: 11vw;
    height: 11vw;
  }
  .day-width.nine{
    width: 11vw;
    height: 11vw;
  }
  .day-width.ten{
    width: 9.5vw;
    height: 9.5vw;
  }
}



.hide{
  display: none;
}

.day.has-events{
  background-color: rgb(186, 236, 252);
  color: black;
  overflow: hidden;
}

.day.current-day{
  background-color: rgb(145, 243, 161);
}

.day.current-day div{
  color: black;
}

.days-events{
  font-size: 10px;
}

.day-events{
  position: absolute;
  background-color: rgb(218, 218, 218);
  border-collapse: collapse;
  padding: 10px;
  border-radius: 3px;
}

.calendar-months{
  display: inline-flex;
  flex-wrap: wrap;
  justify-content: space-around;
}


.edit-calendar{
  font-size: 8px;
}


.year-number{
  margin-left: 20px;
  margin-right: 20px;
}

.calendar-name{
  margin-left: 20px;
  cursor: pointer;
  color: black;
}

.day:hover {
  background-color: rgb(195, 216, 247);
  color: black;
}

.calendar-name:hover{
  text-decoration: underline;
}

.ReactModal__Overlay{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  animation-name: animate-top;
  animation-duration: 0.4s;
  background-color:  rgba(0,0,0,0.5) !important;
}

.modal-wrapper{
  background-color: #ffffff;
  max-height: 90vh;
  overflow: auto;
}




@keyframes animate-top {
  from {top:-300px; opacity:0}
  to {top:0; opacity:1}
}



.fake-link{
  cursor: pointer;
  user-select: none;
}

.fake-link:hover{
  text-decoration: underline;
}

.input-standard{
  border: 1px solid rgb(70, 78, 100);
  border-radius: 3px;
}

.save-button{
  border: 1px solid black;
  background-color: rgb(155, 247, 137);
  font-size: 1.5em;
  margin-top: 10px;
  margin-bottom: 10px;
}

.blue-button{
  border: 1px solid black;
  background-color: rgb(87, 188, 255);
  margin-top: 10px;
  margin-bottom: 10px;
}

.blue-button.bg{
  border: 1px solid black;
  background-color: rgb(87, 188, 255);
  margin-top: 10px;
  margin-bottom: 10px;
  font-weight: bold;
  font-size: 1.2em;
}

.danger-button{
  border: 1px solid black;
  background-color: rgb(230, 30, 30);
  margin-top: 10px;
  margin-bottom: 10px;
}

.danger-button.bg{
  font-size: 1.5em;
}


.user-toolbar{
  position: relative;
  justify-content: left;
}
.user-toolbar div{
  align-self: center;
}

.logout{
  position: absolute;
  background-color: rgba(58, 58, 58);
  width: 100%;
  padding: 10px 10px 5px 10px;
  min-width: 50px;
  white-space: nowrap;
}

.error-modal{
  width: 300px;
  background-color: rgb(255, 255, 255);
  padding: 5px 10px 20px 10px;
  border: 10px solid rgb(255, 238, 0);
}

.error-title{
  display: flex;
  font-size: 20px;
  justify-content: space-around;
  color: rgb(255, 136, 0);
  font-weight: bold;
  padding: 5px 10px 5px 10px;
}

.error-message{
  padding: 5px 10px 5px 10px;
  font-weight: bold;
}

.get-started{
  color: blue;
}

.login-spinner{
  position: relative;
  height: 30px;
  width: 30px;
}

.about{
  padding: 10px;
  position: absolute;
  bottom: 0px;
  right: 0px;
}

.new-banner{
  width: 100%;
  min-height: 100px;
  background-color: rgba(49, 45, 85, 0.9);
  bottom: 0;
  position: fixed;
  color: white;
}

.new-banner > div{
  margin: 5px 20px 5px 20px;
}

.new-banner a{
  color: white;
}

.condensed-placeholder{
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
}

.condensed-placeholder > div{
  align-self: center;
}

.link-blue{
  color: blue;
}